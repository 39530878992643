import React, { useState, useEffect } from "react";
import PostList from "./components/PostList";
import Post from "./components/Post";
import HomePage from "./components/HomePage";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Ticker from "react-ticker";
import { Filter } from "./components/Filter";

import logoTurned from "./images/logo-rotate.svg";
import Tags from "./components/Tags";

function App() {
  const location = useLocation();
  const [red, setRed] = useState(50);
  const [green, setGreen] = useState(50);
  const [blue, setBlue] = useState(50);

  useEffect(() => {
    if (location.pathname === "/") {
      setGreen(73);
      setBlue(58);
      setRed(218);
    } else {
      let color = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue("--main-bg");

      setRed(parseInt(color.slice(1, 3), 16));
      setGreen(parseInt(color.slice(3, 5), 16));
      setBlue(parseInt(color.slice(5, 7), 16));
    }
  }, [location]);

  return (
    <div className="flex flex-col sm:h-screen">
      {location.pathname === "/" && <Tags />}
      <Filter r={red} g={green} b={blue} />
      <div className="md:z-50">
        <Ticker>
          {({ index }) => (
            <p className="cursor-pointer archivo pb-0 pr-1">
              ETRA Radio is now on Internet Public Radio every other tuesday at
              5pm EST!
            </p>
          )}
        </Ticker>
      </div>
      <div className=" sm:flex justify-evenly">
        <div className="z-10 pt-1 archivo w-screen text-center text-5xl etra-red-bg text-white nav fixed top-0 sm:hidden">
          <div
            onClick={() => {
              document.documentElement.style.setProperty(
                "--main-bg",
                "#da4f3a"
              );
            }}
          >
            <Link to="/">ETRA</Link>
          </div>
        </div>
        <div className="items-center justify-center hidden sm:flex">
          <Link to="/">
            <img
              alt="ETRA"
              onClick={() => {
                document.documentElement.style.setProperty(
                  "--main-bg",
                  "#da4f3a"
                );
              }}
              className="logo block"
              src={logoTurned}
            />
          </Link>
        </div>
        <div id="list" className="w-screen">
          <div className="height-rest overflow-auto hidden sm:block">
            <PostList location={location.pathname} />
          </div>
        </div>
        <div
          id="content"
          className="pt-12 sm:mx-0 sm:pt-0 height-rest overflow-auto"
        >
          <TransitionGroup component={null}>
            <CSSTransition key={location.key} classNames="fade" timeout={300}>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={
                    <>
                      <div className="sm:hidden">
                        <PostList location={location.pathname} />
                      </div>
                      <div className="hidden sm:flex justify-center height-rest items-center">
                        <HomePage />
                      </div>
                    </>
                  }
                />
                <Route path="/:authorSlug/:slug" element={<Post />} />
              </Routes>
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
    </div>
  );
}

export default App;
