import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function PostListEntry(props) {
  const [vis, setVis] = useState("hidden");

  return (
    <div
      className="fade-hover flex-1 h-0"
      onMouseEnter={() => {
        setVis("block");
      }}
      onMouseLeave={() => {
        setVis("hidden");
      }}
    >
      <img
        className={`opacity-0 sm:opacity-100 hover-image ${vis}`}
        src={props.postData.image + "?auto=format&w=" + parseInt(props.width)}
        alt={props.postData.alt}
      />
      <Link
        to={props.postData.authorSlug + "/" + props.postData.slug}
        className={props.postData.type}
      >
        <div
          id={props.postData.slug + props.postData.authorSlug}
          className={`my-1 py-2 mx-5 sm:mx-1 flex text-white`}
        >
          <div className="flex items-center mr-2 rotate-180">
            <h6 className="postlist-label m-0">{props.postData.type}</h6>
          </div>
          <div className="w-full flex">
            <div className="h-full w-full  flex flex-col justify-center">
              <div className="archivo">{props.postData.title}</div>
              {props.postData.author !== props.postData.title ? (
                <div className="archivo">{props.postData.author}</div>
              ) : (
                <div className="hidden md:block lg:hidden">
                  {props.postData.date}
                </div>
              )}
            </div>
            <div className="block sm:hidden lg:block pr-3 whitespace-nowrap">
              {props.postData.date}
              <br />
              <br />
            </div>
          </div>
        </div>
        <div className="mx-5 sm:mx-1">
          <hr />
        </div>
      </Link>
    </div>
  );
}
