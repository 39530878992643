import { useState, useEffect } from "react";
import PostListEntry from "./PostListEntry";
import posts from "../posts.json";

function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
}

export default function PostList({ location }) {
  document.addEventListener("mousemove", (event) => {
    document.documentElement.style.setProperty(
      "--cursor-x",
      event.clientX + "px"
    );
    document.documentElement.style.setProperty(
      "--cursor-y",
      event.clientY + "px"
    );
  });

  var width = useWindowSize().width * 0.33;

  var filters = {
    radio: true,
    album: true,
    single: true,
    feature: true,
  };

  function hideType(type) {
    if (
      filters["radio"] &&
      filters["album"] &&
      filters["single"] &&
      filters["feature"]
    ) {
      filters = {
        radio: false,
        album: false,
        single: false,
        feature: false,
      };
      filters[type] = true;
    } else if (filters[type]) {
      filters = {
        radio: true,
        album: true,
        single: true,
        feature: true,
      };
    } else {
      filters = {
        radio: false,
        album: false,
        single: false,
        feature: false,
      };
      filters[type] = !filters[type];
    }

    if (filters["radio"]) {
      document.documentElement.style.setProperty("--radio", "block");
    } else {
      document.documentElement.style.setProperty("--radio", "none");
    }
    if (filters["album"]) {
      document.documentElement.style.setProperty("--album", "block");
    } else {
      document.documentElement.style.setProperty("--album", "none");
    }
    if (filters["single"]) {
      document.documentElement.style.setProperty("--single", "block");
    } else {
      document.documentElement.style.setProperty("--single", "none");
    }
    if (filters["feature"]) {
      document.documentElement.style.setProperty("--feature", "block");
    } else {
      document.documentElement.style.setProperty("--feature", "none");
    }

    if (
      filters["radio"] &&
      filters["album"] &&
      filters["single"] &&
      filters["feature"]
    ) {
      for (let i = 0; i < 4; i++) {
        document.styleSheets[2].cssRules[i].style.color = "white";
        document.styleSheets[2].cssRules[i].style.backgroundColor =
          "rgba(255,0,0,0)";
      }
    } else {
      if (filters["radio"]) {
        document.styleSheets[2].cssRules[0].style.color = "var(--main-bg)";
        document.styleSheets[2].cssRules[0].style.backgroundColor = "white";
      } else {
        document.styleSheets[2].cssRules[0].style.color = "white";
        document.styleSheets[2].cssRules[0].style.backgroundColor =
          "rgba(255,0,0,0)";
      }
      if (filters["album"]) {
        document.styleSheets[2].cssRules[1].style.color = "var(--main-bg)";
        document.styleSheets[2].cssRules[1].style.backgroundColor = "white";
      } else {
        document.styleSheets[2].cssRules[1].style.color = "white";
        document.styleSheets[2].cssRules[1].style.backgroundColor =
          "rgba(255,0,0,0)";
      }
      if (filters["single"]) {
        document.styleSheets[2].cssRules[2].style.color = "var(--main-bg)";
        document.styleSheets[2].cssRules[2].style.backgroundColor = "white";
      } else {
        document.styleSheets[2].cssRules[2].style.color = "white";
        document.styleSheets[2].cssRules[2].style.backgroundColor =
          "rgba(255,0,0,0)";
      }
      if (filters["feature"]) {
        document.styleSheets[2].cssRules[3].style.color = "var(--main-bg)";
        document.styleSheets[2].cssRules[3].style.backgroundColor = "white";
      } else {
        document.styleSheets[2].cssRules[3].style.color = "white";
        document.styleSheets[2].cssRules[3].style.backgroundColor =
          "rgba(255,0,0,0)";
      }
    }
  }

  return (
    <>
      {posts !== undefined && (
        <div>
          <div className="list-choice fixed z-10 pb-1 etra-red-bg">
            <div className="flex sort text-white justify-evenly -mb-2 -mt-6 sm:mt-1 only-small">
              <h5>sort:</h5>
              <p className="radioSel px-1" onClick={() => hideType("radio")}>
                mix
              </p>
              <p className="albumSel px-1" onClick={() => hideType("album")}>
                album
              </p>
              <p className="singleSel px-1" onClick={() => hideType("single")}>
                single
              </p>
              <p
                className="featureSel px-1"
                onClick={() => hideType("feature")}
              >
                feature
              </p>
            </div>
          </div>
          <div className="sm:pt-7 flex flex-col">
            {posts.map((postData, i) => {
              return (
                <PostListEntry
                  width={width}
                  postData={postData}
                  location={location}
                />
              );
            })}
          </div>
        </div>
      )}
    </>
  );
}
