import React from "react";
export function Filter(props) {
  return (
    <svg className="hidden">
      <filter
        id="b"
        colorInterpolationFilters="sRGB"
        x="0"
        y="0"
        height="100%"
        width="100%"
      >
        <feColorMatrix
          result="A"
          colorInterpolationFilters="sRGB"
          in="SourceGraphic"
          type="matrix"
          values=".33 .33 .33 0 0
                 .33 .33 .33 0 0
                 .33 .33 .33 0 0
                 0 0 0 1 0"
        ></feColorMatrix>
        <feComponentTransfer in="colormatrix" result="componentTransfer">
          <feFuncR type="table" tableValues={props.r / 255 + " 1"} />
          <feFuncG type="table" tableValues={props.g / 255 + " 1"} />
          <feFuncB type="table" tableValues={props.b / 255 + " 1"} />
          <feFuncA type="table" tableValues="0 1" />
        </feComponentTransfer>
      </filter>
    </svg>
  );
}
