import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useDimensions from "react-cool-dimensions";
import getPixelRatio from "./getPixelRatio";
import posts from "../posts.json";
import Tags from "./Tags";

export default function Post() {
  const [finalPost, setPost] = useState(undefined);

  const navigate = useNavigate();

  const { observe, width } = useDimensions({});

  const { slug, authorSlug } = useParams();
  var post;

  var foundMatch = false;
  var schemaJson;
  var last;

  useEffect(() => {
    for (var element of posts) {
      if (slug === element.slug && authorSlug === element.authorSlug) {
        foundMatch = true;
        post = element;
        break;
      }
    }

    if (!foundMatch) {
      navigate("/");
    }

    schemaJson = {
      "@context": "https://schema.org",
      "@type": "Newsalbum",
      headline: "Review: " + post.title + " by " + post.author,
      image: [post.image],
      datePublished: post.date,
      dateModified: post.date,
      author: [
        {
          "@type": "Organization",
          name: "ETRA",
          url: "https://etra.live",
        },
      ],
    };

    last = document.getElementsByClassName("inverse")[0];
    if (last) {
      last.classList.remove("inverse");
    }
    document.documentElement.style.setProperty("--main-bg", post.color);
    document.getElementById(slug + authorSlug).classList.add("inverse");
    setPost(post);
  }, posts);

  if (!finalPost) {
    return <></>;
  }

  return (
    <div className="-mt-5 md:mt-0">
      <Tags
        title={
          finalPost.type === "mix"
            ? finalPost.title + " - " + finalPost.date
            : finalPost.title
        }
        alt={finalPost.alt}
        image={finalPost.image}
        desc={finalPost.content}
        color={finalPost.color}
      />

      <div className="text-white pt-2 sm:pt-4 mx-5 sm:mx-0">
        <div className="article-photo lg:mr-4 " ref={observe}>
          {finalPost.type !== "single" && (
            <img
              src={`${finalPost.image}?auto=format&w=${parseInt(
                width * getPixelRatio()
              )}`}
              alt={finalPost.alt}
              className="pb-3 article-photo mx-auto sm:pt-1 w-full "
              primaryColor="#FFFFFF"
              secondaryColor={finalPost.color}
              sizes={width !== undefined ? `${Math.round(width)}px` : "100vw"}
            />
          )}
        </div>
        <div className="text-center sm:text-left">
          <h1
            className="inline"
            dangerouslySetInnerHTML={{ __html: finalPost.title }}
          />
          <p className="sm:inline sm:ml-2 whitespace-nowrap">
            by: {finalPost.author}
          </p>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html:
              "<p>" +
              finalPost.content
                .replace(/(?:\n\n)/g, "</p> <p>")
                .replace(/\*([^*]+?)\*/g, "<i>$1</i>") +
              "</p>",
          }}
        />
      </div>
    </div>
  );
}
