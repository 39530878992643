import React, { useEffect, useState } from "react";
import posts from "../posts.json";
import { Link } from "react-router-dom";
import useDimensions from "react-cool-dimensions";
import getPixelRatio from "../components/getPixelRatio";

export default function HomePage() {
  const [newMix, setMix] = useState(0);
  const [newReview, setReview] = useState(0);

  const { observe, height } = useDimensions({});

  useEffect(() => {
    const last = document.getElementsByClassName("inverse")[0];
    if (last) {
      last.classList.remove("inverse");
    }
    for (let i = 0; i < posts.length; i++) {
      if (posts[i].type === "mix") {
        setMix(posts[i]);
        break;
      }
    }
    for (let i = 0; i < posts.length; i++) {
      if (posts[i].type === "album" || posts[i].type === "single") {
        setReview(posts[i]);
        break;
      }
    }
  }, [posts]);

  return (
    <>
      {posts !== undefined && (
        <div className="flex flex-col sm:px-0 md:px-4 lg:px-0 text-white">
          <div>
            <h1 className="pb-2">Welcome to ETRA</h1>
            <p className="mb-0">
              ETRA is a collective of artists writing about and curating music
              by the creatives of the future.
            </p>
            <p>
              Follow us on{" "}
              <a
                href="https://instagram.com/etra.live"
                target="_blank"
                rel="noreferrer"
              >
                Instagram
              </a>{" "}
              to stay updated, or <a href="mailto:info@etra.live">email us</a>{" "}
              if you want to submit music or a mix.
            </p>
          </div>
          <hr />
          <div className="flex flex-col lg:flex-row w-full">
            <div className="flex-1">
              <h3 className="mt-3 px-1 absolute inverse-stay text-center">
                Latest Mix
              </h3>
              {newMix !== 0 && (
                <Link to={newMix.authorSlug + "/" + newMix.slug}>
                  <div className="home-cover" ref={observe}>
                    <img
                      src={`${newMix.image}?auto=format&h=${parseInt(
                        height * getPixelRatio()
                      )}`}
                      alt={newMix.alt}
                      className="w-full h-full pt-3 lg:pr-2 object-cover"
                      primaryColor="#FFFFFF"
                      secondaryColor="#DA4F3A"
                    />
                  </div>
                </Link>
              )}
            </div>
            <div className="flex-1 home-cover">
              <h3 className="mt-3 px-1 lg:ml-2 absolute inverse-stay text-center">
                Latest Review
              </h3>
              {newReview !== 0 && (
                <Link to={newReview.authorSlug + "/" + newReview.slug}>
                  <div className="home-cover">
                    <img
                      src={`${newReview.image}?auto=format&h=${parseInt(
                        height * getPixelRatio()
                      )}`}
                      alt={newReview.alt}
                      className="lg:pl-2 pt-3 object-cover w-full h-full"
                      primaryColor="#FFFFFF"
                      secondaryColor="#DA4F3A"
                    />
                  </div>
                </Link>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
