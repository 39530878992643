import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";


export default function Tags(props) {
  const location = useLocation();

  const title = props.title ? props.title + " | ETRA" : "ETRA";
  var desc = props.desc
    ? props.desc
    : "ETRA is a collective of artists writing about and curating music by the creatives of the future with music reviews and radio mixes.";
  const image = props.image ? props.image : "https://etra.live/images/banner.png";
  const alt = props.alt
    ? props.alt
    : "ETRA logo";
  const slug = props.slug ? props.slug : location.pathname;
  const color = props.color ? props.color : "#da4f3a";

  if (desc.length > 160) desc = desc.substring(0, 157) + "...";

  return (
    <Helmet>
      <title>{title}</title>

      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta name="twitter:title" content={title} />

      <meta name="description" content={desc} />
      <meta property="og:description" content={desc} />
      <meta name="twitter:description" content={desc} />

      <meta property="og:image" content={image} />
      <meta name="twitter:image" content={image} />

      <meta name="twitter:image:alt" content={alt} />

      <meta property="og:type" content="website" />
      <meta property="og:url" content={"https://etra.live" + slug} />
      <meta name="twitter:url" content={"https://etra.live" + slug} />
      <meta name="twitter:card" content="summary_large_image" />
      <link rel="mask-icon" href="/images/favicon/safari-pinned-tab.svg" color={color} />
      <meta name="msapplication-TileColor" content={color} />
      <meta name="theme-color" content={color} />

    </Helmet>
  );
}
